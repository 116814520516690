import { apiPostRequest } from "../api";
import { getBookingRules } from "../api/bookingRules";
import { getMarketingPreferences, getVenuePackages } from "../api/venue";
import { generateDurations, generateTimeArray, updateElemHTML } from "../helpers";
import { getBookingDetails, getBookingTypeDetails } from "../helpers/bookingData";
import { getTypeFromBookingTypeId } from "../helpers/bookingTypes";
import { getEnquiryDetails, getEnquiryDetailsText } from "../helpers/enquiry";

export const showEnquiryForm = async (widget, bookingForm) => {
    // Get the booking rules
    const bookingRules = await getBookingRules(widget);

    // Get any existing details
    const exisitngDetails = getExisitngDetails(bookingForm, bookingRules);

    // Get the enquiryDetails
    const enquiryDetails = getEnquiryDetails();

    // Get any packages avialable
    const packages = await getVenuePackages();

    // Get the marketing preferences
    const marketingPreferences = await getMarketingPreferences();

    // Get the booking policy from the booking rules
    const bookingPolicy = bookingRules.booking_notes;

    // Check if DOB is required for this booking type
    const bookingType = getBookingTypeDetails();
    const typeOfBooking = getTypeFromBookingTypeId(bookingType.id) === 'Occasions' ? 'space_booking_' : 'table_booking_';

    const formData = new FormData();

    formData.append('existing_details', JSON.stringify(exisitngDetails));
    formData.append('type', enquiryDetails.type);
    formData.append('packages', JSON.stringify(packages));

    if ( bookingType.dob_required ) {
        formData.append('dob', true);
    }

    formData.append('marketing_preferences', JSON.stringify(marketingPreferences));
    formData.append('booking_policy', bookingPolicy);
    formData.append('type_of_booking', typeOfBooking);
    formData.append('action', 'getEnquiryFormHtml');

    const formHTML = await apiPostRequest(formData);

    await updateElemHTML(widget, '.ig-dmn__inner', formHTML);

    const enquiryDescription = await getEnquiryDetailsText();

    await updateElemHTML(widget, '.ig-dmn__details-booking-description-text', enquiryDescription);  
};

const getExisitngDetails = (bookingForm, bookingRules) => {
    const bookingDetails = getBookingDetails();
    
    const dateVal = bookingDetails.date;
    const numPeopleVal = bookingDetails.num_people;
    const timeVal = bookingDetails.time;
    const durationVal = bookingDetails.duration;   

    const exisitngDetails = {
        date: dateVal !== '' ? dateVal : null,
        numPeople: numPeopleVal !== '' ? numPeopleVal : null,
        time: timeVal !== '' ? timeVal : null,
        duration: durationVal !== '' ? durationVal : null,
    };

    return exisitngDetails;
};